
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MvButton, MvIcon } from '@monosolutions/vue-components'

interface ProgressStyle {
  width: string
}

@Component({
  components: {
    MvButton,
    MvIcon
  }
})
export default class Header extends Vue {
    @Prop({ default: 7 }) readonly totalSteps!: number
    @Prop({ default: 1 }) readonly currentStep!: number
    @Prop({ default: false }) readonly templateButtons!: boolean
    @Prop({ default: false }) readonly viewportSwitcher!: boolean

    progressStyle: ProgressStyle = { width: '0%' }
    previewMode = 'desktop'
    mounted (): void {
      this.setProgressAuto()
    }

    get progress (): ProgressStyle {
      if (this.currentStep <= this.totalSteps + 1 &&
          this.$store.state.progress !== 100) {
        return this.setProgressAuto()
      }
      return { width: `${this.$store.state.progress}%` }
    }

    setProgressAuto (): ProgressStyle {
      const percentage = (this.currentStep / (this.totalSteps + 1)) * 100
      this.$store.commit('SET_PROGRESS', percentage)
      return {
        width: this.currentStep < 1
          ? '0%'
          : `${percentage}%`
      }
    }

    setPreview (mode: string): void {
      this.previewMode = mode
      this.$store.dispatch('setPreviewMode', this.previewMode)
    }

    cancelPreview (): void {
      this.$router.push({ name: 'Choose Template' })
    }

    selectTemplate (): void {
      this.$store.dispatch('setUserData', { template: { templateId: this.$route.query.id } })
      this.$router.push({ name: 'Create Account' })
    }
}

