import { Vue, Component } from 'vue-property-decorator'

import Page from '@/models/page'

@Component
export default class PageMixin extends Vue {
  get showTemplateButtons (): boolean | undefined {
    return this.$store.state.currentPage?.headerType === 'template'
  }

  get showViewportSwitcher (): boolean | undefined {
    return this.$store.state.currentPage?.headerType === 'template'
  }

  get showSidebar (): boolean | undefined {
    return this.$store.state.currentPage?.sidebar.show
  }

  get sidebarImg (): string | undefined {
    return (this.$store.state.currentPage?.sidebar.src)
      ? this.$store.state.currentPage?.sidebar.src : undefined
  }

  get getTotalStepCount (): number {
    return this.$store.state.pages.filter((page: Page) => page.step !== undefined).length
  }

  get currentStepNumber (): number | undefined {
    if (this.$store.state.currentPage?.step) {
      return this.$store.state.currentPage?.step
    }
    return undefined
  }
}
