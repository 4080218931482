
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavButton from '@/models/nav-button'
import Page from '@/models/page'
import { bus } from '@/main'
import { MvButton } from '@monosolutions/vue-components'
import { mapGetters } from 'vuex'
import HTTPClient from '@/utils/HTTPClient'

@Component({
  components: {
    MvButton
  },
  computed: {
    ...mapGetters({
      nextStatus: 'nextStatus'
    })
  }
})
export default class Footer extends Vue {
    @Prop({ default: [] }) buttons!: Array<NavButton>
    nextStatus!: boolean

    get footerType (): string | undefined {
      const type = this.$store.state.currentPage?.footer.type !== undefined
        ? this.$store.state.currentPage?.footer.type
        : 'partly'
      return type
    }

    clickedButton (button: NavButton): void {
      const event = `validate${this.$store.state.currentPage.view}`

      bus.$emit(event, button)
    }

    // Methods
    canProceed (): boolean {
      if (this.$store.state.currentPage.step === undefined) {
        return false
      }

      if (!this.$store.state.currentPage.requiresValidation) {
        // No validation required
        return true
      }

      if (!this.$store.state.currentPage.validated) {
        // show the errors
        this.$store.dispatch('showFormErrors', true)
        return false
      }

      return true
    }

    handleClick (button: NavButton) {
      if (button.type === 'next' && !this.canProceed()) {
        return false
      }

      if (this.$store.state.currentPage.view === 'Account' &&
          this.$store.state.userData.account.optin === true) {
        try {
          this.createMailchimpContact()
        } catch (e) {}
      }

      this.navigate(button)
    }

    navigate (button: NavButton): void {
      let nextStep = this.$store.state.currentPage.step

      switch (button.type) {
        case 'back':
          if (button.path !== undefined) {
            window.location.href = button.path
            return
          }
          nextStep--
          break
        case 'skip':
          this.$store.dispatch('showFormErrors', false)
          nextStep++
          break
        case 'next':
          this.$store.dispatch('showFormErrors', false)
          nextStep++
          break
        case 'finish':
          nextStep++
          break
      }

      this.$store.dispatch('setNextStatus', false)
      const nextPage: Page = this.$store.state.pages.find((page: Page) => page.step === nextStep)
      this.$router.push(nextPage.path)
    }

    async createMailchimpContact (): Promise<void> {
      const apiUrl = 'https://mono.site/api.php/diy'
      const apiRequest = new HTTPClient(apiUrl)

      await apiRequest.post('/newsletter/contact', {
        a: {
          email: this.$store.state.userData.account.email,
          optin: this.$store.state.userData.account.optin
        }
      })
    }
}
