import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = store.state.pages.map(({ name, view, path }) => {
  return {
    name,
    path,
    component: () => import(`@/views/${view}.vue`)
  }
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.addRoute({
  name: 'diy-home',
  path: '/',
  redirect: {
    name: store.state.pages[0].name
  }
})

router.beforeEach((to, from, next) => {
  store.commit('SET_ROUTE', to)
  if (store.state.currentPage === null && to.path !== store.state.pages[0].path) {
    store.dispatch('setCurrentPage', store.state.pages[0])
    next(store.state.pages[0].path)
    return false
  }
  const currentPage = store.state.pages.find(page => page.path === to.path)
  store.dispatch('setCurrentPage', currentPage)
  next()
})

export default router
