import Vue from 'vue'
import Vuex from 'vuex'
import Page from '@/models/page'
import { cloneDeep, merge } from 'lodash'
import UserData from '@/models/user-data'

import pageJson from '@/config/pages.json'

Vue.use(Vuex)
interface RootState {
  pages: Array<Page>
  route: any | undefined
  currentPage: Page | null
  nextStatus: boolean
  previewMode: string | null
  progress: number
  showFormErrors: boolean,
  flowStarted: boolean,
  userData: UserData,
  templatesList: []
}

export default new Vuex.Store<RootState>({
  state: {
    pages: pageJson,
    route: undefined,
    currentPage: null,
    progress: 0,
    showFormErrors: false,
    nextStatus: false,
    flowStarted: false,
    previewMode: 'desktop',
    userData: {
      account: {
        email: '',
        password: '',
        username: '',
        optin: false
      },
      template: {
        templateId: undefined
      },
      company: {
        name: ''
      },
      businessInfo: {},
      skill: {},
      vertical: {}
    },
    templatesList: []
  },
  mutations: {
    SET_ROUTE (state, route) {
      state.route = route
    },
    SET_FLOW_STARTED (state, flowStarted: boolean) {
      state.flowStarted = flowStarted
    },
    SET_CURRENT_PAGE (state, page: Page) {
      state.currentPage = page
    },
    SET_PREVIEW_MODE (state, mode) {
      state.previewMode = mode
    },
    SET_PROGRESS (state, value) {
      state.progress = value
    },
    SET_CURRENT_PAGE_VALID (state, page: Page) {
      page.validated = true
      state.currentPage = page
    },
    SET_CURRENT_PAGE_INVALID (state, page: Page) {
      page.validated = false
      state.currentPage = page
    },
    SET_USER_DATA (state, userData) {
      state.userData = userData
    },
    SET_NEXT_STATUS (state, flag) {
      state.nextStatus = flag
    },
    SET_SHOW_FORM_ERRORS (state, flag) {
      state.showFormErrors = flag
    },
    SET_TEMPLATES_LIST (state, templatesList) {
      state.templatesList = templatesList
    }
  },
  actions: {
    setUserData ({ commit }, userData) {
      const updatedUserData = merge(cloneDeep(this.state.userData), userData)
      commit('SET_USER_DATA', updatedUserData)
    },
    setCurrentPage ({ commit }, page: Page) {
      if (page.requiresValidation === undefined) {
        const hasSkipButton = page.footer.buttons.filter(button => button.type === 'skip')
        page.requiresValidation = hasSkipButton.length === 0
        if (page.requiresValidation) {
          page.validated = false
          page.showFormErrors = false
        }
      }
      commit('SET_CURRENT_PAGE', page)
    },
    setCurrentPageValid ({ commit }) {
      commit('SET_CURRENT_PAGE_VALID', this.state.currentPage)
    },
    setCurrentPageInvalid ({ commit }) {
      commit('SET_CURRENT_PAGE_INVALID', this.state.currentPage)
    },
    showFormErrors ({ commit }, flag) {
      commit('SET_SHOW_FORM_ERRORS', flag)
    },
    setFlowStarted ({ commit }, flowStarted) {
      commit('SET_FLOW_STARTED', flowStarted)
    },
    setPreviewMode ({ commit }, mode) {
      commit('SET_PREVIEW_MODE', mode)
    },
    setNextStatus ({ commit }, flag) {
      commit('SET_NEXT_STATUS', flag)
    },
    setTemplatesList ({ commit }, templatesList) {
      commit('SET_TEMPLATES_LIST', templatesList)
    }
  },
  getters: {
    userData: state => state.userData,
    formErrors: state => state.showFormErrors,
    previewMode: state => state.previewMode,
    nextStatus: state => state.nextStatus,
    flowStarted: state => state.flowStarted,
    templatesList: state => state.templatesList,
    currentPage: state => state.currentPage
  },
  modules: {
  }
})
