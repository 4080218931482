import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export default class HTTPClient {
  instance: AxiosInstance
  apiUrl: string
  contentType = 'application/json'

  constructor (apiUrl = '', contentType: string | undefined = undefined) {
    this.apiUrl = apiUrl

    if (contentType) {
      this.contentType = contentType
    }

    this.instance = axios.create({
      baseURL: this.apiUrl,
      withCredentials: false, // Temp change to allow origin *
      headers: {
        'Content-Type': this.contentType
      }
    })
  }

  get (endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.instance.get(endpoint, config)
  }

  post (endpoint: string, params = {}, opts = {}): Promise<any> {
    return this.instance.post(endpoint, params, opts)
  }

  patch (endpoint: string, params = {}, opts = {}): Promise<any> {
    return this.instance.patch(endpoint, params, opts)
  }

  put (endpoint: string, params = {}, opts = {}): Promise<any> {
    return this.instance.put(endpoint, params, opts)
  }

  delete (endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.instance.delete(endpoint, config)
  }
}
