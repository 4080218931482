
import { Component, Mixins } from 'vue-property-decorator'
import HeaderEl from '@/components/Header.vue'
import FooterEl from '@/components/Footer.vue'
import { MvIconSprite } from '@monosolutions/vue-components'

import PageMixin from '@/mixins/PageMixin'

@Component({
  components: {
    HeaderEl,
    FooterEl,
    MvIconSprite
  }
})
export default class App extends Mixins(PageMixin) {
  get showFooter (): boolean | undefined {
    return this.$store.state.currentPage?.footer.show
  }

  get footerButtonArray (): Array<any> | undefined {
    return this.$store.state.currentPage?.footer.buttons
  }
}
